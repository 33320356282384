































































import {Component, Vue} from "vue-property-decorator";
import axios from "axios";
import ControlPanelParticipant from "@/components/controlpanel/participants/ControlPanelParticipant.vue";
import TextInput from "@/components/form/TextInput.vue";
import FormButton from "@/components/form/Button.vue";
import draggable from "vuedraggable";
import ControlPanelParticipantMemberList from "@/components/controlpanel/participants/ControlPanelParticipantMemberList.vue";
import VueSelect from "vue-select";

@Component({
  components: {
    ControlPanelParticipantMemberList,
    ControlPanelParticipant,
    TextInput,
    FormButton,
    draggable,
    VueSelect,
  },
})
export default class ParticipantsControlPanel extends Vue {
  public participants: any[] = [];
  public SMEs: any[] = [];
  public types: any[] = [];

  public expandedId: number = -1;

  public editMemberListParticipant: any = {members: []};
  public newMemberName = "";
  public newMemberNameWarning = false;

  public newTypeId = "1";
  public newName = "";
  public newNameWarning = false;
  public newDepartment = "";
  public newCity = "";
  public newCityWarning = false;
  public newCountry = "";
  public newCountryWarning = false;
  public newLatitude = "";
  public newLongitude = "";

  public created() {
    axios.get("/controlpanel/participants/types").then((res) => {
      this.participants = res.data.find((t: any) => t.id == 1).participants;
      this.SMEs = res.data.find((t: any) => t.id == 2).participants;
      this.types = res.data.map((t: any) => {
        return {id: t.id, type: t.type};
      });
    });
  }

  public toggleExpand(participant: any) {
    if (participant.id == this.expandedId) {
      this.expandedId = -1;
    } else {
      this.expandedId = participant.id;
    }
  }

  public isExpanded(id: number) {
    return this.expandedId == id;
  }

  public getParticipant(participant: any) {
    switch (parseInt(participant.typeId, 10)) {
      case 1:
        return this.participants.find((p) => p.id == participant.id);
      case 2:
        return this.SMEs.find((p) => p.id == participant.id);
      default:
        return null;
    }
  }

  public editParticipant(changedParticipant: any) {
    const original = this.getParticipant(changedParticipant);
    original.name = changedParticipant.name;
    original.department = changedParticipant.department;
    original.city = changedParticipant.city;
    original.country = changedParticipant.country;
    original.longitude = changedParticipant.longitude;
    original.latitude = changedParticipant.latitude;
  }

  public editMembers(participant: any) {
    this.editMemberListParticipant = participant;
    this.$modal.show("edit-members");
  }

  public changeMemberOrder(list: any) {
    const newOrders = list.map((member: any, index: any) => {
      return {id: member.id, order: index};
    });

    this.editMemberListParticipant.members = list;

    const participant = this.getParticipant(this.editMemberListParticipant);

    axios.patch("/controlpanel/participants/members/order", {orders: newOrders}).then(() => {
      participant.members = list;
      this.editMemberListParticipant = participant;
    }).catch(() => {
      this.editMemberListParticipant = participant;
    });
  }

  public deleteMember(member: any) {
    axios.delete("/controlpanel/participants/members/" + member.id).then(() => {
      const participant = this.getParticipant(this.editMemberListParticipant);
      const memberIndex = participant.members.findIndex((m: any) => m.id == member.id);
      participant.members.splice(memberIndex, 1);
    });
  }

  public addMember() {
    if (!this.validateNewMember()) {
      return;
    }
    const participant = this.getParticipant(this.editMemberListParticipant);
    const newMember = {name: this.newMemberName};

    axios.post(`/controlpanel/participants/${participant.id}/members`, newMember).then((res) => {
      const {id, order} = res.data;
      participant.members.push({id, order, ...newMember});
      this.editMemberListParticipant = participant;
      this.newMemberName = "";
    });
  }

  public validateNewMember() {
    this.newMemberNameWarning = !this.newMemberName;
    return !this.newMemberNameWarning;
  }

  get participantsList() {
    return this.participants;
  }

  set participantsList(val) {
    const newOrders = val.map((publication: any, index: any) => {
      return {id: publication.id, order: index};
    });
    axios.patch("/controlpanel/participants/order", {orders: newOrders}).then(() => {
      this.participants = val;
    });
  }

  get SMEsList() {
    return this.SMEs;
  }

  set SMEsList(val) {
    const newOrders = val.map((publication: any, index: any) => {
      return {id: publication.id, order: index};
    });
    axios.patch("/controlpanel/participants/order", {orders: newOrders}).then(() => {
      this.SMEs = val;
    });
  }

  public addParticipant() {
    if (!this.verify()) {
      return;
    }

    const participant = {
      name: this.newName,
      department: this.newDepartment,
      city: this.newCity,
      country: this.newCountry,
      latitude: this.newLatitude ? this.newLatitude : null,
      longitude: this.newLongitude ? this.newLongitude : null,
      participant_type_id: this.newTypeId,
    };

    axios.post("/controlpanel/participants", participant).then((res) => {
      const {id, order} = res.data;
      switch (parseInt(participant.participant_type_id, 10)) {
        case 1:
          this.participants.push({id, order, typeId: participant.participant_type_id, members: [], ...participant});
          break;
        case 2:
          this.SMEs.push({id, order, typeId: participant.participant_type_id, members: [], ...participant});
          break;
        default:
          break;
      }
      this.$modal.hide("add-participant");
      this.newName = "";
      this.newDepartment = "";
      this.newCity = "";
      this.newCountry = "";
      this.newLatitude = "";
      this.newLongitude = "";
      this.newTypeId = "1";
    });
  }

  public verify() {
    this.newNameWarning = !this.newName;
    this.newCityWarning = !this.newCity;
    this.newCountryWarning = !this.newCountry;

    return !this.newNameWarning && !this.newCityWarning && !this.newCountryWarning;
  }

  public deleteParticipant(participant: any) {
    switch (parseInt(participant.typeId, 10)) {
      case 1:
        this.participants.splice(this.participants.findIndex((p) => p.id == participant.id), 1);
        break;
      case 2:
        this.SMEs.splice(this.SMEs.findIndex((p) => p.id == participant.id), 1);
        break;
      default:
        break;
    }
  }
}
