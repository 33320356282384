
















import {Component, Prop, Vue} from "vue-property-decorator";
import Draggable from "vuedraggable";

@Component({
  props: ["members"],
  components: {
    Draggable,
  },
})
export default class ControlPanelParticipantMemberList extends Vue {
  @Prop()
  public members: any;

  public deleteMember(member: any) {
    this.$emit("deleteMember", member);
  }

  get memberList() {
    return this.members;
  }

  set memberList(list) {
    this.$emit("changeOrder", list);
  }
}
