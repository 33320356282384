












































import {Component, Prop, Vue} from "vue-property-decorator";
import TextInput from "@/components/form/TextInput.vue";
import FormButton from "@/components/form/Button.vue";
import axios from "axios";

@Component({
  components: {TextInput, FormButton},
  props: ["participant", "expanded"],
})
export default class ControlPanelParticipant extends Vue {
  @Prop()
  public participant: any;

  @Prop(Boolean)
  public expanded: boolean;

  public editName = this.participant.name;
  public editNameWarning = false;
  public editDepartment = this.participant.department;
  public editCity = this.participant.city;
  public editCityWarning = false;
  public editCountry = this.participant.country;
  public editCountryWarning = false;
  public editLatitude = this.participant.latitude;
  public editLongitude = this.participant.longitude;


  public toggleExpand() {
    this.$emit("toggleExpand", this.participant);
  }

  public editMembers() {
    this.$emit("editMembers");
  }

  public deleteParticipant() {
    if (confirm("Are you sure you want to delete this participant?")) {
      axios.delete("/controlpanel/participants/" + this.participant.id).then(() => {
        this.$emit("delete", this.participant);
      });
    }
  }

  public saveParticipant() {
    if (this.validate()) {
      const changedParticipant = {
        id: this.participant.id,
        name: this.editName,
        department: this.editDepartment,
        city: this.editCity,
        country: this.editCountry,
        longitude: this.editLongitude,
        latitude: this.editLatitude,
      };
      axios.patch("/controlpanel/participants/" + this.participant.id, changedParticipant).then(() => {
        this.toggleExpand();
        this.$emit("edit", {typeId: this.participant.typeId, ...changedParticipant});
      });
    }
  }

  // public deletePublication() {
  //     if (confirm("Are you sure you want to delete this publication?")) {
  //         axios.delete("/controlpanel/publications/" + this.publication.id).then(() => {
  //             this.toggleExpand();
  //             this.$emit("delete", this.publication.id);
  //         })
  //     }
  // }
  //

  public validate() {
    this.editNameWarning = !this.editName;
    this.editCityWarning = !this.editCity;
    this.editCountryWarning = !this.editCountry;

    return !this.editNameWarning && !this.editCityWarning && !this.editCountryWarning;
  }
}
